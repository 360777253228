import React, { useRef } from 'react'

import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Button, Col, Row, Image } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { debounce, trim } from 'lodash'
import TextArea from 'antd/lib/input/TextArea'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import { ConfigProviderProps } from 'antd/lib/config-provider'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { showMessageError } from 'src/helpers/functions'
import { CONFIG_URL, getPage } from 'src/helpers/constants'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
   }
}

export const ModalDetailWapper = styled.div`
   .btn-close-image {
      line-height: 1.3;
      position: absolute;
      top: 2px;
      right: 0;
   }
   .price {
      display: none;
   }
   @media screen and (max-width: 820px) {
      .price {
         display: flex;
      }
      .product-fee {
         display: none;
      }
   }
`
interface ImageType {
   path: string
   description: string
}
const CreateProductScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [status, setStatus] = useState(parseInt(item?.status))

   const UserStore = useStore('UserStore')
   const { rows: listSeller } = UserStore.listSeller
   const ProductStore = useStore('ProductStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const { rows } = CommonConfigStore.tagList
   const images = ProductStore.jobDetail?.images
   const AuthStore = useStore('AuthStore')
   const [form] = Form.useForm()
   const [imageCurrent, setImageCurrent] = useState([])
   const [tags, setTags] = useState<any>([])
   const [tagSelected, setTagSelected] = useState([])
   type SizeType = ConfigProviderProps['componentSize']
   const fileInputRef = useRef<HTMLInputElement>(null)
   const [editedImage, setEditedImage] = useState<ImageType>({
      path: '',
      description: ''
   })

   useEffect(() => {
      if (rows && rows?.length > 0) {
         const tag = rows.map((ro: any) => {
            return { value: ro?.id + '', label: ro?.name }
         })
         setTags(tag)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rows])

   useEffect(() => {
      if (!rows || rows.length < 1) {
         const offset = getPage(0)
         const params = { offset, limit: 1000 }
         //  console.log('params', params)
         CommonConfigStore.getTagList(params)
      }
      if (!listSeller || listSeller.length < 1) {
         getListSeller()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   const getListSeller = async () => {
      try {
         // setIsLoading(true)
         const offset = getPage(0)
         const params = { offset }
         // console.log('params', params)

         await UserStore.getSeller(params)
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }

   const handleChange = (value: string | string[]) => {
      console.log(`Selected: ${value}`)
   }

   const onFinish = async (values: any) => {
      console.log('Received values of form: ', values, 'images: ', imageCurrent, ' tagSelected', tagSelected)
      const imgs = imageCurrent.map((im: any) => {
         return im?.path
      })
      // return
      const result = await ProductStore.createProduct({
         name: values?.name,
         tags: values?.tags,
         sellerIDs: values?.sellerIDs || [],
         images: imgs,
         description: values?.description,
         weigh: values?.weigh
      })
      if (result) {
         form.resetFields()
         onOk()
      }
   }
   const handleImagesUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files ? event.target.files : null
      const allowedTypes = ['image/jpeg', 'image/png']
      let isValidFile = true

      if (fileList) {
         if (fileList.length > 5 || fileList.length + imageCurrent.length > 5) {
            isValidFile = false
         }
         for (var i = 0; i < fileList.length; i++) {
            var file = fileList[i]
            var fileType = file.type
            if (!allowedTypes.includes(fileType)) {
               isValidFile = false
               break
            }
         }
      }

      if (fileList && isValidFile) {
         try {
            // setIsLoading(true)
            const uploadRequests = Array.from(fileList).map(async (file, index) => {
               const { data } = await Request2.uploadFile(file, api.upload.uploadFile)
               return {
                  path: data.filename,
                  description: `Ảnh ${images?.length + 1 + index}`
               }
            })
            const newImages = await Promise.all(uploadRequests)
            const updatedImages: any = [...imageCurrent, ...newImages]
            // console.log('images', images)
            console.log('newImages', newImages, 'updatedImages', updatedImages)
            setImageCurrent(updatedImages)
            ProductStore.setJobDetailImages(updatedImages)
            // console.log('updatedImages', updatedImages)
         } catch (error) {
            // console.error(error)
         } finally {
            // setIsLoading(false)
         }
      } else {
         showMessageError(t(GLOBAL_CLIENT.validateanh))
      }
      if (fileInputRef.current) {
         fileInputRef.current.value = ''
      }
   }

   const handleImageDelete = (id: number) => {
      const filterImages = [...imageCurrent]
      filterImages?.splice(id, 1)
      console.log('filterImages', filterImages)

      setImageCurrent(filterImages)
      // setEditedImageId(null)
      // clearImage()
   }

   const handleEditClick = (image: any, id: number) => {
      // setEditedImageId(id)
      if (image) {
         setEditedImage({
            path: image?.path,
            description: image?.description?.trim()
         })
      }
   }
   const renderContent = () => {
      return (
         <ModalDetailWapper>
            <Form
               {...formItemLayout}
               form={form}
               name="CreatorDetail"
               onFinish={debounce(onFinish, 400)}
               scrollToFirstError
            >
               <Row>
                  <Col span={24}>
                     <Form.Item
                        name="name"
                        label={'Tên sản phẩm'}
                        required
                        rules={[
                           //   {
                           //      required: true,
                           //      message: t(GLOBAL_CLIENT.noTrim)
                           //   },
                           ({ getFieldValue }) => ({
                              validator(_, value) {
                                 if (trim(value) !== '') {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(t(GLOBAL_CLIENT.noTrim))
                              }
                           })
                        ]}
                     >
                        <Input maxLength={100} />
                     </Form.Item>

                     <Form.Item name="weigh" label={'Cân nặng'} initialValue={item?.weigh}>
                        <Input type="number" maxLength={100} />
                     </Form.Item>

                     {/* <Form.Item name="sellerOrderId" label={'SKU'} initialValue={item?.order}>
                        <Input maxLength={30} />
                     </Form.Item> */}

                     <Form.Item name="tags" label={'Tag'}>
                        <Select
                           mode="tags"
                           size={'middle'}
                           placeholder="Chọn"
                           defaultValue={tagSelected}
                           onChange={handleChange}
                           options={tags}
                        />
                     </Form.Item>
                     <Form.Item name="sellerIDs" label={'Seller'}>
                        <Select
                           placeholder="Chọn seller"
                           mode="multiple"
                           showSearch
                           optionFilterProp="children" // Filters based on option's label (name in this case)
                           filterOption={(input, option) =>
                              (option?.children as string).toLowerCase().includes(input.toLowerCase())
                           }
                        >
                           {listSeller.map((item: any) => {
                              return (
                                 <Select.Option key={item?.id + ''} value={item?.id + ''}>
                                    {item?.name}
                                 </Select.Option>
                              )
                           })}
                        </Select>
                     </Form.Item>
                     <Form.Item name="images" label={'Ảnh'}>
                        <input
                           ref={fileInputRef}
                           type="file"
                           style={{}}
                           onChange={handleImagesUpload}
                           multiple
                           accept="image/png, image/jpeg"
                        />
                        <div className="gx-flex-row gx-align-items-center">
                           {imageCurrent &&
                              imageCurrent.map((item: any, index) => (
                                 <div key={index} style={{ position: 'relative', padding: '10px' }}>
                                    <Image
                                       width={100}
                                       preview={{
                                          mask: null
                                       }}
                                       src={item?.path ? CONFIG_URL.IMAGE_URL + '/' + item?.path : ''}
                                    />
                                    <Button
                                       style={{ background: '#f44336', color: 'white' }}
                                       size={'small'}
                                       shape="circle"
                                       icon={<CloseOutlined />}
                                       className="btn-close-image"
                                       onClick={() => handleImageDelete(index)}
                                    ></Button>
                                 </div>
                              ))}
                           {/* <Button
                              size={'large'}
                              shape="circle"
                              icon={<PlusOutlined />}
                              style={{ lineHeight: '1.3', marginLeft: '10px' }}
                           ></Button> */}
                        </div>
                     </Form.Item>

                     <Form.Item name="description" initialValue={item?.description} label={'Mô tả sản phẩm'}>
                        <TextArea maxLength={255} style={{ height: 120, resize: 'none' }} />
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
         </ModalDetailWapper>
      )
   }

   return !onlyView ? (
      <Modal
         width={'90%'}
         style={{ maxWidth: '900px' }}
         visible={visible}
         centered
         title={'Thêm sản phẩm'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'%'}
         style={{ maxWidth: '900px' }}
         visible={visible}
         centered
         title={'Thêm sản phẩm'}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default CreateProductScreen
