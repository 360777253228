import axios from 'axios'
import { CONFIG_URL, LOCAL_STORAGE, ERROR_CODES, TIME_OUT_API } from '../helpers/constants'
import moment from 'moment'
import { showMessageError } from '../helpers/functions'
import api from './api'

export const Request2 = {
   async header() {
      return await axios.create({
         baseURL: CONFIG_URL.SERVICE_URL,
         timeout: TIME_OUT_API,
         headers: {
            'Content-Type': 'application/json'
         }
      })
   },

   async headerFile() {
      return await axios.create({
         baseURL: CONFIG_URL.SERVICE_URL,
         timeout: TIME_OUT_API,
         headers: {
            'Content-Type': 'multipart/form-data'
         }
      })
   },

   async headerWithToken() {
      let token = ''
      const tokensString = await localStorage.getItem(LOCAL_STORAGE.DATA_AUTH)
      if (tokensString) {
         const tokens = JSON.parse(tokensString)?.tokens
         // console.log('tokens', tokens)
         token = tokens?.access?.token
         const tokenExpiresTime = tokens?.access?.expires
         // Thời điểm hiện tại  có là sau thời điểm tokenExpiresTime hay không?
         const isAfter = moment().isAfter(tokenExpiresTime)
         const refreshToken = tokens?.refresh?.token
         const refreshTokenExpiresTime = tokens?.refresh?.expires
         const isAfterRefresh = moment().isAfter(refreshTokenExpiresTime)
         // Nếu đúng: token này đã hết hạn và cần Refresh lại
         if (isAfter) {
            localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            const result = await this.post({ refreshToken: refreshToken }, api.auth.refreshToken)
            if (result?.code === ERROR_CODES.UNAUTHORIZED) {
               // showMessageError('Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại!', () => {
               //    window.location.href = `${window.location.origin}/signin`
               // })
               return false
            }
            localStorage.setItem(LOCAL_STORAGE.DATA_AUTH, JSON.stringify({ tokens: result?.data }))
            token = result?.data?.access?.token
         } else if (isAfterRefresh) {
            localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError('Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại!', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
            return false
         }
      } else {
         localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
         return false
         // console.log('Token không tồn tại')
      }
      return await axios.create({
         baseURL: CONFIG_URL.SERVICE_URL,
         timeout: TIME_OUT_API,
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
         }
      })
   },

   async post(body, url) {
      try {
         let api = await this.header()
         const res = await api.post(url, body)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data || true
         } else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
            await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError(res?.data?.message || '', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
            return res?.data
         } else if (
            res?.data?.code === ERROR_CODES.REQUEST_FAILED &&
            res?.data?.message === 'Error: Tài khoản cần được xác thực'
         ) {
            return { isAccountNotActive: true }
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---post-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else if (error.response?.data?.message === 'Error: Tài khoản cần được xác thực') {
            return { isAccountNotActive: true }
         } else {
            showMessageError(error.response?.data?.message)
         }
      }
   },

   async postWithToken(body, url) {
      try {
         let api = await this.headerWithToken()
         const res = await api.post(url, body)
         // console.log('respostWithToken ', res.data, 'body: ', body, 'url; ', url)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data || true
         } else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
            await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError(res?.data?.message || '', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         // console.log('error ', JSON.stringify(error), 'error; ', error?.message)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---postWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            // console.log('run vao day nè', error, ' ')
            showMessageError(error?.message)
         }
      }
   },

   async putWithToken(body, url) {
      try {
         let api = await this.headerWithToken()
         if (!api) return false
         const res = await api.put(url, body)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data || true
         } else {
            showMessageError(res?.data?.message || '')
            return false
         }
      } catch (error) {
         // console.log('error ', error, 'error; ', error?.message)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---putWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error.response?.data?.message)
         }
         return false
      }
   },

   async patchWithToken(body, url) {
      try {
         let api = await this.headerWithToken()
         const res = await api.patch(url, body)
         // console.log('respostWithToken ', res.data, 'body: ', body, 'url; ', url)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data?.data || true
         } else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
            await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError(res?.data?.message || '', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         // console.log('error ', error, 'error; ', error?.message)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---patchWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
      }
   },

   async deleteWithToken(body, url) {
      try {
         let api = await this.headerWithToken()
         const res = await api.delete(url, body)
         // console.log('respostWithToken ', res.data, 'body: ', body, 'url; ', url)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data?.data || true
         } else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
            await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError(res?.data?.message || '', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         // console.log('error ', error, 'error; ', error?.message)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---deleteWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
      }
   },

   async get(url) {
      try {
         let api = await this.header()
         const res = await api.get(url)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data?.data || true
         } else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
            await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
            showMessageError(res?.data?.message || '', () => {
               setTimeout(() => {
                  window.location.href = `${window.location.origin}/signin`
               }, 500)
               return
            })
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         // console.log('err', error)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---get-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
      }
   },

   async getWithToken(url, params = {}) {
      try {
         let api = await this.headerWithToken()
         if (!api) return false
         const res = await api.get(url, { params })
         // console.log('url-', url, 'res ne---------', res)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data?.data || true
         }
         //  else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
         //    await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
         //    showMessageError(res?.message || '')
         else {
            showMessageError(res?.data?.message || '')
            return false
         }
      } catch (error) {
         // ('errror---:  ', error)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---getWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
         return false
      }
   },

   async getWithTokenNoMessage(url, params = {}) {
      try {
         let api = await this.headerWithToken()
         if (!api) return false
         const res = await api.get(url, { params })
         // console.log('url-', url, 'res ne---------', res)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            return res?.data?.data || true
         }
         //  else if (res?.data?.code === ERROR_CODES.UNAUTHORIZED) {
         //    await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH)
         //    showMessageError(res?.message || '')
         else {
            // showMessageError(res?.data?.message || '')
            return false
         }
      } catch (error) {
         // console.log('errror---:  ', error)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---getWithToken-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         }
         return false
      }
   },

   async getFile(url, body = {}) {
      try {
         let api = await this.header()
         const res = await api.post(url, body, { responseType: 'blob' })

         // Lấy header content-type để kiểm tra kiểu dữ liệu trả về
         const contentType = res.headers['content-type']

         // Nếu trả về JSON thì đọc blob thành text và parse JSON
         if (contentType && contentType.includes('application/json')) {
            const reader = new FileReader()
            reader.onload = () => {
               try {
                  const jsonData = JSON.parse(reader.result)
                  console.error('API trả về JSON:', jsonData)
                  // Hiển thị thông báo lỗi cho người dùng, ví dụ:
                  // alert('Đã xảy ra lỗi: ' + (jsonData.message || 'Không có dữ liệu file PDF'))
                  showMessageError(jsonData.message || '')
               } catch (error) {
                  console.error('Không thể parse JSON:', error)
               }
            }
            reader.readAsText(res.data)
            return null // Dừng xử lý nếu không có file PDF hợp lệ
         }

         console.log('ressssss', res.data)
         if (!res.data || res.data.size === 0) {
            console.error('Không có dữ liệu trả về từ API')
            // Xử lý theo yêu cầu, có thể hiển thị thông báo lỗi hoặc thông báo cho người dùng
            return null
         }

         // Tạo một Blob từ dữ liệu trả về và xác định loại file là PDF
         const file = new Blob([res.data], { type: 'application/pdf' })
         const fileURL = URL.createObjectURL(file)
         return fileURL

         // // Tạo URL từ Blob để mở file PDF trên trình duyệt
         // const fileURL = URL.createObjectURL(file)

         // // Mở file PDF trong cửa sổ mới
         // const pdfWindow = window.open(fileURL)

         // // Sau khi cửa sổ tải xong, gọi hàm in
         // pdfWindow.addEventListener('load', () => {
         //    pdfWindow.print()
         // })
      } catch (error) {
         // console.log('err', error)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---get-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
      }
   },

   async getFileWithToken(url, params = {}) {
      try {
         let api = await this.headerWithToken()
         const res = await api.get(url, { params, responseType: 'blob' })

         // Lấy header content-type để kiểm tra kiểu dữ liệu trả về
         const contentType = res.headers['content-type']

         // Nếu trả về JSON thì đọc blob thành text và parse JSON
         if (contentType && contentType.includes('application/json')) {
            const reader = new FileReader()
            reader.onload = () => {
               try {
                  const jsonData = JSON.parse(reader.result)
                  console.error('API trả về JSON:', jsonData)
                  // Hiển thị thông báo lỗi cho người dùng, ví dụ:
                  // alert('Đã xảy ra lỗi: ' + (jsonData.message || 'Không có dữ liệu file PDF'))
                  showMessageError(jsonData.message || '')
               } catch (error) {
                  console.error('Không thể parse JSON:', error)
               }
            }
            reader.readAsText(res.data)
            return null // Dừng xử lý nếu không có file PDF hợp lệ
         }

         console.log('ressssss', res.data)
         if (!res.data || res.data.size === 0) {
            console.error('Không có dữ liệu trả về từ API')
            // Xử lý theo yêu cầu, có thể hiển thị thông báo lỗi hoặc thông báo cho người dùng
            return null
         }

         const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
         return fileUrl

         // // Tạo URL từ Blob để mở file PDF trên trình duyệt
         // const fileURL = URL.createObjectURL(file)

         // // Mở file PDF trong cửa sổ mới
         // const pdfWindow = window.open(fileURL)

         // // Sau khi cửa sổ tải xong, gọi hàm in
         // pdfWindow.addEventListener('load', () => {
         //    pdfWindow.print()
         // })
      } catch (error) {
         // console.log('err', error)
         if (error?.message === 'Network Error') {
            showMessageError('Không có kết nối. Vui lòng thử lại')
         } else if (error?.message?.indexOf('timeout of') !== -1) {
            // console.log('---get-timeout of--------')
            // await localStorage.removeItem(LOCAL_STORAGE.DATA_AUTH);
            // showMessageError("Hệ thống đang nâng cấp. Vui lòng thử lại sau");
         } else {
            showMessageError(error?.message)
         }
      }
   },

   async uploadFile(file, url) {
      try {
         let api = await this.headerFile()
         var bodyFormData = new FormData()
         bodyFormData.append('file', file)
         const res = await api.post(url, bodyFormData)
         // console.log('resresresres', res)
         // console.log('res?.data?.code', res?.data?.code)
         if (res?.data?.code === ERROR_CODES.SUCCESS) {
            // console.log('vao dayyyyyyyy')
            return res?.data
         } else {
            showMessageError(res?.data?.message || '')
         }
      } catch (error) {
         if (error?.response?.status) {
            showMessageError('Bạn chỉ có thể upload ảnh tối đa là 5MB.')
         } else {
            if (error?.message === 'Network Error') {
               showMessageError('Không có kết nối. Vui lòng thử lại')
            } else if (error?.message?.indexOf('timeout of') !== -1) {
               // console.log('---uploadFile-timeout of--------')
               // showMessageError("Không có kết nối. Vui lòng thử lại");
            } else {
               showMessageError(error?.message)
            }
         }
      }
   }
}
