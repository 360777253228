import { useMemo } from 'react'
import { Modal, Form, Button, Typography, Steps } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { ListStatusATG } from '../../helpers/constants'
import { includes } from 'lodash'
import styled from 'styled-components'

export const ModalAssignOrderWapper = styled.div`
   .expense-wrapper {
      border-left: 1px solid #e8e8e8;
   }

   label[for='CreatorOrder_shippingOption']:after {
      content: '';
   }

   @media screen and (max-width: 991px) {
      .expense-wrapper {
         border-left: 1px solid transparent;
      }
   }
`
const DeliveryDetailScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onCancel } = props

   const [form] = Form.useForm()

   const listStatus = useMemo(() => {
      const listData = []
      for (let index = 1; index < ListStatusATG.length; index++) {
         listData.unshift(ListStatusATG[index])
         if (includes(ListStatusATG[index].value, item?.deliveryState)) {
            break
         }
      }
      return listData
   }, [item])

   const handleCancel = () => {
      onCancel()
   }

   const renderContent = () => {
      return (
         <>
            <ModalAssignOrderWapper>
               <Steps style={{ paddingLeft: '40px' }} progressDot direction="vertical" current={0}>
                  {listStatus.map((v, index) => {
                     return <Steps.Step key={index} title={v?.label} />
                  })}
               </Steps>
            </ModalAssignOrderWapper>
         </>
      )
   }

   return (
      <Modal
         width={'60%'}
         style={{ maxWidth: '1000px' }}
         visible={visible}
         centered
         title={'Chi tiết đơn ' + item?.code}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={handleCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default DeliveryDetailScreen
